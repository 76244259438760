<template>
  <div class="home">
    <van-nav-bar title="动态" id="reset" />
    <van-tabs v-model="active" title-inactive-color="#8D8D8D" title-active-color="#1583D2" color="#1583D2">
      <van-tab title="线索">
    <div  v-for="(item,index) in list" :key="index" class="kehu" @click=routeTo(item.customer_id)>
          <div class="header">
            <img src="../static/动态详情1/touxiang.png" alt="">
            <div>
              <div><span class="name">{{item.user_name}}</span><span class="tit">{{item.role_name}}</span></div>
              <div class="txt">{{item.create_time}} {{item.content}}</div>
            </div>
            <div>
              <img src="../static/动态/fanhui.png" alt="" class="back">
            </div>
          </div>
          <div class="contain">
            <div class="left">
              <p>客户名称：{{item.aname}}</p>
              <p>线索来源：{{item.datab}}</p>
              <!-- <p>下次联系</p> -->
            </div>
            <div class="right">
              <img src="../static/动态/xiansuo.png" alt="">
            </div>
          </div>
        </div>
      </van-tab>
  <!--    <van-tab title="客户">客户</van-tab>
      <van-tab title="商机">商机</van-tab>
      <van-tab title="合作">合作</van-tab> -->
    </van-tabs>
  </div>
</template>


<script>
	import moment from 'moment';

export default {
  data() {
    return {
      active: 0,
	  list:{},
	  
    };
  },created () {
    this.getHistoryData()
  },
methods:{
	routeTo(customer_id)
	{
 		console.log(customer_id);
		this.$router.push({
		  path: `/kehuxiangqing?id=`+customer_id,
		})
		
	},
	dateFormat(row, column) {
      var date = row[column.property];
      if (date == undefined) {
        return "";
      }
      return moment(date).format("YYYY-MM-DD");
    },
 
    onClickLeft() {
    this.$router.go(-1);//返回上一层
    },
 
 getHistoryData(){
 	let param = new URLSearchParams();
 	
 	param.append("by", 'customer');
 	 param.append("token", this.$store.getters. getDemoValue);
 	
 	this.axios.post('/vue.php?m=index&a=dynamic',param).then(res => {
 		if (res.data.status === -1) {

 			setTimeout(()=>{
 				this.$router.push({
 				  path:"/"
 				})
 			},1000)
 		}
 		if (res.data.status === 1) {
 			
			this.list = res.data.list;
 			console.log(this.list);
 		
 		}
 		console.log(res);
 	}).catch(function (error){
 		console.log(error);
 	});
 }
 
},

};
</script>

<style lang="scss" scoped>
#reset ::v-deep .van-nav-bar__title {
  color: #ffffff !important;
}
.van-nav-bar {
  background: #1288f4;
}
.home {
  background: #f2f2f2;
  padding-bottom: 40px;
}
.kehu {
  width: 345px;
  height: 139px;
  background: #ffffff;
  box-shadow: 0px 0px 8px 0px rgba(44, 49, 140, 0.11);
  border-radius: 5px;
  margin: 15px auto;
  box-sizing: border-box;
  padding: 5px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  .name {
    width: 37px;
    height: 18px;
    font-size: 19px;
    font-weight: normal;
    color: #373c40;
    margin-right: 5px;
  }
  .tit {
    width: 111px;
    height: 10px;
    font-size: 11px;
    font-weight: normal;
    color: #8d8d8d;
  }
  .txt {
    width: 180px;
    height: 10px;
    margin-top: 10px;
    font-size: 11px;
    font-weight: normal;
    color: #8d8d8d;
  }
  img {
    width: 38px;
    height: 37px;
  }
  .back {
    width: 10px;
    height: 17px;
  }
}
.contain {
  padding: 0 5px;
  display: flex;
  height: 60%;
  .left {
    width: 75%;
    p {
      height: 10px;
      font-size: 11px;
      font-weight: normal;
      color: #8d8d8d;
      margin: 15px 0;
    }
  }
  .right {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 20%;
    img {
      width: 27px;
      height: 27px;
    }
  }
}
</style>